import PrinterStatusModal from './PrinterStatusModal';
import {
  Button,
  Icon,
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Modal,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table, Checkbox,
  Segment,
  Popup,
  Select
} from 'semantic-ui-react';
import { Input } from 'semantic-ui-react'
import { search, labelsByOrderSerial, print, printBox } from '../actions/label-actions';
import React, { useEffect, useState, Fragment } from 'react';
import AirflowPreview from './preview/AirflowPreview';
import SFOM_ENUMS from '../enums/sfom';

export default function PrintModal(props) {
  const [query, setQuery] = useState('')
  const [busy, setBusy] = useState(false)
  const [results, setResults] = useState([])
  const [labelResults, setLabelResults] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [togglePreview, setTogglePreview] = useState(false)
  const [openPrinterModal, setOpenPrinterModal] = useState(false)
  const [encodedZPL, setEncodedZPL] = useState([])
  const [selectedFilter, setSelectedFilter] = useState('')
  const [labelMode, setLabelMode] = useState(SFOM_ENUMS.LABEL.TYPE.AIRFLOW)

  useEffect(() => {
    reset()
  }, [props.open])

  const reset = (() => {
    setResults([])
    setLabelResults([])
    setSelectAll(false)
    setSelectedFilter('')
  })

  const handleQueryChange = ((event) => {
    const { value } = event.target
    setQuery(value.trim())
  })

  const handleLabelModeChange = ((_event, data) => {
    const { value } = data
    setLabelMode(value)
  })

  const handleCustomerOnLabel = (() => {
    const labelsMutable: any = labelResults.map((labelMutable: any) => {
      labelMutable.customerOnLabel = !labelMutable.customerOnLabel
      return labelMutable
    })
    setLabelResults(labelsMutable)
  })

  const handleUserInitials = ((initials: string) => {
    const clean = initials.trim().substring(0, 3)
    const labelsMutable: any = labelResults.map((labelMutable: any) => {
      labelMutable.initials = clean
      return labelMutable
    })
    setLabelResults(labelsMutable)
  })

  const handleSelectAll = ((override = false) => {
    let shouldSelectAll = !selectAll
    if (override) {
      shouldSelectAll = false
    }
    const labelsMutable: any = labelResults.map((labelMutable: any) => {
      if (selectedFilter !== '' && labelMutable.filterType !== selectedFilter) {
        labelMutable.print = false
      } else {
        labelMutable.print = shouldSelectAll
      }
      return labelMutable
    })
    setSelectAll(shouldSelectAll)
    setLabelResults(labelsMutable)
  })

  const handlePrintToggle = ((index) => {
    const labelsMutable: any = [...labelResults];
    const labelMutable: any = labelResults[index]
    labelMutable.print = !labelMutable.print
    labelsMutable[index] = labelMutable
    const toggledCount: any = labelsMutable.filter((label: any) => label.print)
    setSelectAll(toggledCount.length === labelsMutable.length)
    setLabelResults(labelsMutable)
  })

  const handleBoxQuantity = ((index, quantity) => {
    quantity = Number(quantity)
    if (quantity < 1) {
      quantity = 1
    }
    const labelsMutable: any = [...labelResults];
    const labelMutable: any = labelResults[index]
    labelMutable.boxQuantity = quantity
    labelsMutable[index] = labelMutable
    setLabelResults(labelsMutable)
  })

  const handleLabelsPerBoxQuantity = ((index, quantity) => {
    quantity = Number(quantity)
    if (quantity < 1) {
      quantity = 1
    }
    const labelsMutable: any = [...labelResults];
    const labelMutable: any = labelResults[index]
    labelMutable.labelsPerBox = quantity
    labelsMutable[index] = labelMutable
    setLabelResults(labelsMutable)
  })

  const handleCustomRef = ((index, ref) => {
    const labelsMutable: any = [...labelResults];
    const labelMutable: any = labelResults[index]
    labelMutable.customRef = ref
    labelsMutable[index] = labelMutable
    setLabelResults(labelsMutable)
  })

  const handleQuantityChange = ((index, quantity) => {
    quantity = Number(quantity)
    if (quantity < 1) {
      quantity = 1
    }
    const labelsMutable: any = [...labelResults];
    const labelMutable: any = labelResults[index]
    labelMutable.quantity = quantity
    labelsMutable[index] = labelMutable
    setLabelResults(labelsMutable)
  })

  const isBoxLabelMode = labelMode === SFOM_ENUMS.LABEL.TYPE.BOX

  const printJobLength = labelResults.filter((label: any) => label.print).reduce((a, label: any) => {
    return a + (isBoxLabelMode ? +label.boxQuantity : +label.quantity)
  }, 0)
  const firstCustomerOnLabelItem: any = labelResults.find((label: any) => 'customerOnLabel' in label)
  const firstConsignmentItem: any = labelResults.find((label: any) => 'consignment' in label)
  const uniqueFilterTypes = [...new Set(labelResults.map((label: any) => label.filterType))];

  const handlePrint = (async () => {
    setBusy(true)
    setOpenPrinterModal(true)
    setBusy(false)
  })

  const getLabelsFromOrder = (async (orderSerial) => {
    const labelsBySerial: any = await labelsByOrderSerial(orderSerial)
    const transformed = labelsBySerial.map((label) => {
      return {
        ...label,
        print: false,
        customerOnLabel: false,
        customRef: '',
        labelsPerBox: label.quantity,
        boxQuantity: 1
      }
    })
    setLabelResults(transformed)
  })

  useEffect(() => {
    setBusy(true)
    setLabelResults([])
    setSelectAll(false)
    setSelectedFilter('')
    const doSearch = setTimeout(async () => {
      search(query).then((results: any) => {
        setBusy(false)
        setResults(results)
      }).catch((e) => {
        setBusy(false)
        console.log(e)
      })
    }, 800)
    return () => clearTimeout(doSearch)
  }, [query])

  useEffect(() => {
    setSelectAll(false)
    handleSelectAll(true)
  }, [selectedFilter, labelMode])

  return (<Modal
    onClose={() => props.setOpen(false)}
    onOpen={() => props.setOpen(true)}
    open={props.open}
    size='large'
  >
    <ModalHeader>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <div>{firstConsignmentItem ? `Consignment ${+firstConsignmentItem.consignment} (${firstConsignmentItem.order})` : 'Search by Consignment'}</div>
        <div style={{ fontSize: 'medium', width: '12em', fontWeight: 'normal' }}>
          <Select disabled={labelResults.length < 1} onChange={handleLabelModeChange} value={labelMode} fluid options={[{ key: 0, value: 0, text: 'Airflow Label', selected: true }, { key: 1, value: 1, text: 'Box Label' }]} />
        </div>
      </div>
    </ModalHeader>
    <ModalContent scrolling>
      <ModalDescription style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Input
          icon={{ name: 'search', circular: true, link: true }}
          placeholder='Search...'
          loading={busy}
          style={{ flex: 1 }}
          onChange={handleQueryChange}
        />
        <Popup position='bottom right' on='click' wide hoverable trigger={<Button color={selectedFilter !== '' ? 'blue' : undefined} style={{ display: 'flex', marginLeft: '1em' }} disabled={labelResults.length < 1} basic icon='filter' />}>
          <Button onClick={() => setSelectedFilter('')} basic compact size='mini' fluid style={{ marginBottom: '0.5em' }}>Clear</Button>
          {uniqueFilterTypes.map((type) => {
            return <div key={type} style={{ padding: '0.2em 0' }}>
              <Checkbox
                radio
                label={type}
                value={type}
                onChange={() => setSelectedFilter(type)}
                checked={type === selectedFilter}
              />
            </div>
          })}
        </Popup>
        {labelResults.length > 0 ?
          <Table basic unstackable>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>{labelMode === SFOM_ENUMS.LABEL.TYPE.BOX ? 'Per Box' : 'Quantity'}</TableHeaderCell>
                {labelMode === SFOM_ENUMS.LABEL.TYPE.BOX ? <TableHeaderCell>Boxes</TableHeaderCell> : null}
                <TableHeaderCell>Type</TableHeaderCell>
                <TableHeaderCell>Info</TableHeaderCell>
                <TableHeaderCell>Reference</TableHeaderCell>
                <TableHeaderCell>
                  <div onClick={() => handleSelectAll()} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                    <Checkbox fitted checked={selectAll} style={{ marginRight: '5px' }} /> Print
                  </div>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {labelResults.map((result: any, index: number) => {
                return (<Fragment key={`${result.orderSerial}-${result.labelSerial}-${index}`}>
                  <TableRow style={selectedFilter !== '' ? result.filterType !== selectedFilter ? { display: 'none' } : {} : {}}>
                    <TableCell disabled={!result.print}>
                      {isBoxLabelMode ?
                        <Input disabled={!result.print} size='mini' fluid type="number" value={result.labelsPerBox} onChange={((_event, data) => handleLabelsPerBoxQuantity(index, data.value))} /> :
                        <Input disabled={!result.print} size='mini' fluid type="number" value={result.quantity} onChange={((_event, data) => handleQuantityChange(index, data.value))} />}
                    </TableCell>
                    {labelMode === SFOM_ENUMS.LABEL.TYPE.BOX ?
                      <TableCell disabled={!result.print}>
                        <Input disabled={!result.print} size='mini' fluid type="number" value={result.boxQuantity} onChange={((_event, data) => handleBoxQuantity(index, data.value))} />
                      </TableCell> : null}
                    <TableCell disabled={!result.print} style={{ fontWeight: 'bold' }}>
                      {result.filterType}
                    </TableCell>
                    <TableCell disabled={!result.print}>
                      {result.filterMeta}
                    </TableCell>
                    <TableCell disabled={!result.print}>
                      <Input disabled={!result.print} size='mini' maxLength="20" placeholder='Reference' value={result.customRef} onChange={((_event, data) => handleCustomRef(index, data.value))} />
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      <Checkbox fitted checked={result.print} onChange={() => handlePrintToggle(index)} />
                    </TableCell>
                  </TableRow>
                  {togglePreview && !isBoxLabelMode ? <AirflowPreview selectedFilter={selectedFilter} result={result} /> : null}
                </Fragment>);
              })}
            </TableBody>
          </Table> : results.length > 0 ?
            <Table basic>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Consignment</TableHeaderCell>
                  <TableHeaderCell>Labels</TableHeaderCell>
                  <TableHeaderCell>Date Processed <Icon name='sort ascending' /></TableHeaderCell>
                  <TableHeaderCell>Upload User</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {results.map((result: any, index: number) => {
                  const dateAdded = new Date(result.dateAdded).toLocaleDateString(
                    'en-gb',
                    {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    }
                  );
                  return (
                    <TableRow key={`${result.orderSerial}-${index}`}>
                      <TableCell>
                        {result.consignment}
                      </TableCell>
                      <TableCell>
                        <Button compact size='small' basic color='blue' onClick={() => getLabelsFromOrder(result._id)}>
                          View Labels
                        </Button>
                      </TableCell>
                      <TableCell>
                        {dateAdded}
                      </TableCell>
                      <TableCell>{result.parent.username}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table> : null}
      </ModalDescription>
      <PrinterStatusModal
        open={openPrinterModal}
        setOpen={setOpenPrinterModal}
        handleUserInitials={handleUserInitials}
        reducedLabels={labelResults.filter((label: any) => label.print)}
        printJobLength={printJobLength}
        isBoxLabelMode={isBoxLabelMode}
      />
    </ModalContent>
    <ModalActions>
      <div style={{ float: 'left' }}>
        <Popup on='click' wide hoverable trigger={<Button disabled={labelResults.length < 1} basic icon='ellipsis vertical' />}>
          <Segment secondary style={{ fontSize: 'smaller' }}>
            <Checkbox disabled={isBoxLabelMode} toggle label='Label Preview' checked={!isBoxLabelMode && togglePreview} onChange={() => setTogglePreview(!togglePreview)} />
          </Segment>
          <Segment secondary compact>
            <Checkbox disabled={isBoxLabelMode} toggle label='Customer on Label' checked={!isBoxLabelMode && (firstCustomerOnLabelItem && firstCustomerOnLabelItem.customerOnLabel)} onChange={() => handleCustomerOnLabel()} />
          </Segment>
        </Popup>
      </div>
      <Button basic loading={busy} disabled={busy || printJobLength < 1} primary onClick={() => handlePrint()}>Print {printJobLength} {labelMode === SFOM_ENUMS.LABEL.TYPE.BOX ? 'Box' : 'Airflow'} Labels</Button>
      <Button basic disabled={busy} onClick={() => props.setOpen(false)}>Close</Button>
    </ModalActions>
  </Modal >)
}
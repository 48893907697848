import {
  Button,
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Modal,
  Message,
  Icon,
  Input,
  Divider
} from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { getDefaultPrinter, getPrinterStatus, sendPrintJob, decodeZPL } from '../actions/zebra-actions';
import { print, printBox } from '../actions/label-actions';

let encodedZPL: any = ''

export default function PrinterStatusModal(props) {
  const { setOpen, handleUserInitials, reducedLabels, open, printJobLength, isBoxLabelMode } = props
  const [busy, setBusy] = useState(false)
  const [printerError, setPrinterError] = useState('')
  const [printerState, setPrinterState] = useState('')
  const [printInitiated, setPrintInitiated] = useState(false)
  const [initials, setInitials] = useState('')
  const [initialsValid, setInitialsValid] = useState(false)

  useEffect(() => {
    setBusy(false)
    setPrinterError('')
    setPrinterState('')
    setPrintInitiated(false)
    setInitials('')
    setInitialsValid(false)

    if (isBoxLabelMode) {
      setTimeout(() => {
        setInitials("NA")
        setInitialsValid(true)
      }, 800)

    }
  }, [open])

  useEffect(() => {
    const trimmed = initials.replace(/[^a-zA-Z]/g, "")
    const valid = trimmed.length > 1
    setInitialsValid(valid)
    if (valid) {
      handleUserInitials(trimmed)
    }
  }, [initials])

  const initiatePrint = async function (retry = false) {
    setPrintInitiated(true)
    setBusy(true)
    setPrinterError('')
    setPrinterState('Building ZPL...')

    console.log('retry?', retry)
    if (!retry) {
      console.log('retrying')
      try {
        if (isBoxLabelMode) {
          const printRequest: any = await printBox(reducedLabels)
          encodedZPL = printRequest.data
        } else {
          const printRequest: any = await print(reducedLabels)
          encodedZPL = printRequest.data
        }
      } catch (e) {
        setPrinterError('Unable to prepare ZPL')
      }
    }

    setPrinterState('Communicating with Zebra Printer...')
    setTimeout(async () => {
      try {
        await getDefaultPrinter()
        try {
          const status = await getPrinterStatus()
          if (status.isReadyToPrint) {
            console.log('encoded zpl', encodedZPL)
            const zpl = await decodeZPL(encodedZPL)
            sendPrintJob(zpl);
            setPrinterState('Print job sent')
            setBusy(false)
          } else {
            setPrinterError(status.errors);
          }
        } catch (e) {
          console.log(e)
          setPrinterError('Unable to get Zebra Printer state')
        }
      } catch (e) {
        console.log(e)
        setPrinterError('Unable to find Zebra Printer')
      }
    }, 1000)
  }

  useEffect(() => {
    if (printerError !== '') {
      setBusy(false)
    }
  }, [printerError])

  return (<Modal
    onClose={() => props.setOpen(false)}
    onOpen={() => props.setOpen(true)}
    open={props.open}
    size='mini'
  >
    <ModalHeader>Zebra Print{busy ? <Icon loading name='sync' style={{ float: 'right', marginTop: '3px' }} /> : null}</ModalHeader>
    <ModalContent scrolling>
      <ModalDescription>
        <Message><p style={{ fontFamily: 'monospace', fontSize: 'smaller' }}>
          {!printInitiated ? <>{printJobLength} labels queued in job</> : null}
          {printerError !== '' ? <><Icon name='exclamation triangle'></Icon>{printerError}</> : printerState}
        </p></Message>
        {printerError.includes('Unable to find') ? <p style={{ fontSize: 'small', margin: 0 }}><a href='https://www.zebra.com/gb/en/support-downloads/software/printer-software/browser-print.html' target='_blank'>Zebra Browser Print</a> is required to communicate</p> : null}
        {!isBoxLabelMode && !printInitiated ? <><Divider />
          <p style={{ fontSize: 'smaller', marginTop: 0 }}>Enter your initials below, then click Print.</p>
          <Input fluid size='mini' label='Initials' placeholder='SF' value={initials} onChange={((_event, data) => setInitials(`${data.value.toUpperCase().substring(0, 3)}`))} />
        </> : null}
      </ModalDescription>
    </ModalContent>
    <ModalActions>
      <Button basic disabled={!initialsValid || (printInitiated && printerError === '')} onClick={() => initiatePrint(printInitiated)}>{printInitiated ? 'Retry' : 'Print'}</Button>
      <Button basic onClick={() => setOpen(false)}>Close</Button>
    </ModalActions>
  </Modal>)
}